// Should be in sync with data-v2
export enum Permission {
    Access = "access",
    AccessAssignmentsManage = "access:assignments:manage",
    AccessAssignmentsView = "access:assignments:view",
    AccessPermissionsManage = "access:permissions:manage",
    AccessPermissionsView = "access:permissions:view",
    AccessRolesManage = "access:roles:manage",
    AccessRolesView = "access:roles:view",
    Bookmark = "bookmark",
    BookmarkManage = "bookmark:manage",
    BookmarkRead = "bookmark:read",
    Clash = "clash",
    ClashManage = "clash:manage",
    ClashRead = "clash:read",
    Context = "context",
    ContextAddFileToBasket = "context:addFileToBasket",
    ContextArea = "context:area",
    ContextClip = "context:clip",
    ContextHide = "context:hide",
    ContextHideLayer = "context:hideLayer",
    ContextLaser = "context:laser",
    ContextMeasure = "context:measure",
    ContextPickPoint = "context:pickPoint",
    ContextPointLine = "context:pointLine",
    Deviation = "deviation",
    DeviationRead = "deviation:read",
    DeviationWrite = "deviation:write",
    File = "file",
    FileDownload = "file:download",
    FileList = "file:list",
    FileManage = "file:manage",
    FilePipeline = "file:pipeline",
    Forms = "forms",
    FormsDelete = "forms:delete",
    FormsFill = "forms:fill",
    FormsManage = "forms:manage",
    FormsView = "forms:view",
    Group = "group",
    GroupManage = "group:manage",
    GroupRead = "group:read",
    Int = "int",
    IntArcgis = "int:arcgis",
    IntArcgisManage = "int:arcgis:manage",
    IntArcgisUse = "int:arcgis:use",
    IntBimcollab = "int:bimcollab",
    IntBimcollabManage = "int:bimcollab:manage",
    IntBimcollabUse = "int:bimcollab:use",
    IntBimTrack = "int:bimTrack",
    IntBimTrackManage = "int:bimTrack:manage",
    IntBimTrackUse = "int:bimTrack:use",
    IntDitio = "int:ditio",
    IntDitioManage = "int:ditio:manage",
    IntDitioUse = "int:ditio:use",
    IntJira = "int:jira",
    IntJiraManage = "int:jira:manage",
    IntJiraUse = "int:jira:use",
    IntOmegaPims365 = "int:omegaPims365",
    IntOmegaPims365Manage = "int:omegaPims365:manage",
    IntOmegaPims365Use = "int:omegaPims365:use",
    IntXsiteManage = "int:xsiteManage",
    IntXsiteManageManage = "int:xsiteManage:manage",
    IntXsiteManageUse = "int:xsiteManage:use",
    Org = "org",
    OrgManage = "org:manage",
    OrgProjectsCreate = "org:projects:create",
    OrgProjectsManage = "org:projects:manage",
    Scene = "scene",
    SceneManage = "scene:manage",
    SceneView = "scene:view",
    Widget = "widget",
    WidgetArea = "widget:area",
    WidgetClippingPlanes = "widget:clippingPlanes",
    WidgetFollowPath = "widget:followPath",
    WidgetHeightProfile = "widget:heightProfile",
    WidgetImages = "widget:images",
    WidgetManhole = "widget:manhole",
    WidgetMeasure = "widget:measure",
    WidgetModelTree = "widget:modelTree",
    WidgetMyLocation = "widget:myLocation",
    WidgetOffline = "widget:offline",
    WidgetOrthoCam = "widget:orthoCam",
    WidgetOutlineLaser = "widget:outlineLaser",
    WidgetPointLine = "widget:pointLine",
    WidgetProperties = "widget:properties",
    WidgetPropertyTree = "widget:propertyTree",
    WidgetRangeSearch = "widget:rangeSearch",
    WidgetSearch = "widget:search",
    WidgetSelectionBasket = "widget:selectionBasket",
    WidgetShareLink = "widget:shareLink",
}
